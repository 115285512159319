import { Box, Stack, Typography } from "@mui/material"

export default function WelcomeSection() {
  return (
    <Box component="section" sx={{ my: 5, mx: { md: 12 } }}>
      <Typography id={"home-content"} sx={{ typography: { xs: "h5" }, mb: 2 }}>
        Bem-vindo ao MS Qualifica Digital!
      </Typography>
      <Stack spacing={2}>
        <Typography variant="body1" color="text.secondary">
          Sua porta de entrada para oportunidades profissionais em Mato Grosso do Sul
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Conectamos empregadores e candidatos em uma plataforma completa, feita para
          impulsionar seu sucesso profissional. Em breve, você poderá acessar vouchers de
          qualificação para aprimorar suas habilidades e avançar em sua carreira. Construa
          o futuro que você merece com o MS Qualifica Digital!
        </Typography>
      </Stack>
    </Box>
  )
}
