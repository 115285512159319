import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material"
import React from "react"

interface AccordionPanelProps {
  id: string
  title: string
  children: React.ReactNode
  defaultExpanded?: boolean
  strongTitle?: boolean
}

export class AccordionPanel extends React.Component<AccordionPanelProps> {
  render() {
    const { id, title, children, defaultExpanded, strongTitle } = this.props
    return (
      <Accordion
        defaultExpanded={defaultExpanded}
        sx={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id={id}>
          <Typography>{strongTitle ? <strong>{title}</strong> : title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div id={id + "-details"}>{children}</div>
        </AccordionDetails>
      </Accordion>
    )
  }
}
