"use client"

import { Box, Grid, Stack, Typography } from "@mui/material"
import { ReactNode } from "react"

interface StepCompanyCardProps {
  icon: ReactNode
  title: string
  content: ReactNode
}

export function StepCompanyCard({ icon, title, content }: StepCompanyCardProps) {
  return (
    <Grid
      item
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      md={4}
      xs={12}
      mt={{ md: 1, lg: 5 }}
      sx={{ padding: "0 1rem" }}
    >
      <Stack
        sx={{
          border: "1px solid #004F9F26",
          borderRadius: "15px",
          padding: 1,
          height: "100%",
          width: {
            sm: "100%",
            md: "unset",
          },
          margin: "0.5rem 0",
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          {icon}
        </Box>

        <Typography
          mb={2}
          color={"#114E99"}
          variant="h5"
          fontWeight={400}
          textAlign={"center"}
          sx={{ flexGrow: 1, fontSize: { md: "1.25rem", lg: "1.5rem" } }}
        >
          {title}
        </Typography>

        <Stack
          sx={{
            minHeight: { sm: "unset", md: "120px" },
            flexGrow: 1,
            textAlign: "center",
            lineHeight: { md: 1.3, lg: 1.5 },
            paddingBottom: { sm: "2rem", md: "unset" },
          }}
        >
          {content}
        </Stack>
      </Stack>
    </Grid>
  )
}
