"use client"

import { AccordionPanel } from "@/components/ui/accordions/AccordionPanel"
import { getAssetsPath } from "@/utils/getAssetsPathUtils"
import { Grid, Grow, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { useState } from "react"
import { InView } from "react-intersection-observer"

export default function FrequentlyQuestions() {
  const [isFrequentlyQuestionsLoaded, setIsFrequentlyQuestionsLoaded] = useState(false)

  return (
    <section>
      <InView
        as="section"
        onChange={(inView) => {
          setIsFrequentlyQuestionsLoaded(inView)
        }}
        threshold={0}
        triggerOnce
      >
        <Grow key={undefined} in={isFrequentlyQuestionsLoaded} timeout={1000}>
          <Grid
            container
            sx={{ marginBottom: 4 }}
            alignItems={"stretch"}
            id={"company-tutorial"}
          >
            <Grid
              item
              xs={12}
              sm={8}
              md={3}
              sx={{
                backgroundImage: {
                  sm: "unset",
                  md: `url(${getAssetsPath()}/img/blue-background-arrow.svg)`,
                },
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                borderRadius: "16px",
              }}
            >
              <Typography
                mb={3}
                variant="h6"
                textAlign={"center"}
                sx={{
                  height: "100%",
                  color: { sm: "rgba(0,0,0,0.75)", md: "#fff" },
                  textAlign: { sm: "left", md: "right" },
                  fontSize: "2rem",
                  padding: "1rem",
                  fontWeight: 500,
                }}
              >
                Perguntas Frequentes
              </Typography>
            </Grid>

            <Grid sx={{ paddingX: "15px" }} item md={9} xs={12}>
              <Box sx={{ height: "100%" }}>
                <Box mb={1}>
                  <AccordionPanel
                    id="1"
                    title="Como aumentar minhas chances de ser selecionado?"
                    defaultExpanded
                    strongTitle
                  >
                    Mantenha seus dados cadastrais sempre atualizados para que
                    empregadores consigam entrar em contato com você. Para se destacar
                    entre os outros candidatos, busque uma formação na sua área de
                    interesse na aba “Cursos”.
                  </AccordionPanel>
                </Box>

                <Box mb={1}>
                  <AccordionPanel
                    id="2"
                    title="O MS Qualifica Digital é da FUNTRAB?"
                    defaultExpanded
                    strongTitle
                  >
                    O MS Qualifica Digital, assim como a FUNTRAB, é uma iniciativa do
                    Governo do Estado de Mato Grosso do Sul. São plataformas diferentes
                    que compartilham a mesma base de dados, permitindo que vagas de
                    emprego, currículos e cursos cadastrados no MS Qualifica Digital
                    estejam disponíveis para usuários da FUNTRAB e vice-versa.
                  </AccordionPanel>
                </Box>

                <Box>
                  <AccordionPanel
                    id="3"
                    title="Porque tenho que acessar com minha conta gov.br?"
                    defaultExpanded
                    strongTitle
                  >
                    No MS Qualifica Digital, tanto trabalhadores quanto empresas utilizam
                    o login gov.br para garantir mais segurança e simplicidade no acesso.
                    Esse é o mesmo sistema usado em diversos serviços oficiais do governo,
                    garantindo que suas informações estejam sempre protegidas. Com uma
                    única conta, você acessa todos os serviços disponíveis, seja para
                    gerenciar seu perfil de trabalhador ou cadastrar sua empresa, de forma
                    prática e segura.
                  </AccordionPanel>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grow>
      </InView>
    </section>
  )
}
