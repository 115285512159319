import { Box, Grow, Skeleton, Stack } from "@mui/material"

interface HomeCardsSkeletonProps {
  numberOfCards?: number
}

export default function HomeCardsSkeleton({ numberOfCards = 3 }: HomeCardsSkeletonProps) {
  return (
    <Box component="section">
      <Stack
        width="100%"
        sx={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexDirection: { xs: "column", md: "row" },
          gap: 5,
          my: 5,
          height: "100%",
        }}
      >
        {Array.from({ length: numberOfCards }).map((_, index) => (
          <Grow key={index} in={true}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Stack
                sx={{
                  borderRadius: 1,
                  backgroundColor: "white",
                  padding: 3,
                  flex: 1,
                }}
              >
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="225px"
                  sx={{ borderRadius: 1 }}
                />
                <Stack direction="row" height="45px" sx={{ flexGrow: 1 }}>
                  <Skeleton variant="text" width="100%" />
                </Stack>
                <Stack
                  direction="row"
                  height="35px"
                  spacing={1}
                  mt={4}
                  mb={1}
                  sx={{ flexGrow: 1 }}
                >
                  <Skeleton variant="text" width="30%" />
                </Stack>
              </Stack>
            </div>
          </Grow>
        ))}
      </Stack>
    </Box>
  )
}
