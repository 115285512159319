"use client"

import { getAssetsPath } from "@/utils/getAssetsPathUtils"
import { Grid, Typography } from "@mui/material"
import Image from "next/image"
import { ReactNode } from "react"

interface StepCompanyCardProps {
  title: string
  image: string
  content: ReactNode
}

export function StepCompanyDescription({ title, image, content }: StepCompanyCardProps) {
  return (
    <>
      <Grid item xs={8} sm={5} md={4} mx={"auto"} paddingX={5} mb={{ md: 1, lg: 2 }}>
        <Image
          src={`${getAssetsPath()}${image}`}
          alt="Logo"
          width={0}
          height={0}
          style={{ width: "100%", height: "auto" }}
        />
      </Grid>

      <Grid item md={12} mx={"auto"} textAlign={"center"} paddingX={5}>
        <Typography
          color="#009640"
          variant="h4"
          sx={{
            fontSize: { md: "1.8rem", lg: "2rem" },
            lineHeight: { md: 1.3, lg: 1.5 },
          }}
        >
          {title}
        </Typography>

        <Typography textAlign={"justify"} my={{ xs: 2, sm: 2, md: 2, lg: 3 }}>
          {content}
        </Typography>
      </Grid>

      <Grid item md={12} mx={"auto"} paddingX={5} mt={5}>
        <Typography textAlign={"center"} color={"#646464"}>
          VEJA O PASSO A PASSO:
        </Typography>
      </Grid>
    </>
  )
}
