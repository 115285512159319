"use client"

import { Box, BoxProps } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import { useCallback, useEffect } from "react"

interface LoadingAnimationProps {
  text?: string
  timeout?: number
  onTimeout?: () => void
  isVisible?: boolean
}

const containerStyles: BoxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "50vh",
}

/**
 * A loading animation component.
 *
 * @param {LoadingAnimationProps} props - The props for the LoadingAnimation component.
 * @param {string} props.text - The text to display below the loading animation, default='Carregando...'.
 * @param {number} props.timeout - The timeout in milliseconds to call the onTimeout function.
 * @param {() => void} props.onTimeout - The function to call when the timeout is reached, default=5000.
 * @param {boolean} props.isVisible - Whether the component is visible or not.
 * @returns {JSX.Element} The LoadingAnimation component.
 */
export function LoadingAnimation({
  text = "Carregando...",
  timeout = 3500,
  onTimeout,
  isVisible = true,
}: Readonly<LoadingAnimationProps>): JSX.Element | null {
  const defaultTimeoutHandler = useCallback(() => {
    if (typeof window !== "undefined") {
      window.location.reload()
    }
  }, [])

  const handleTimeout = onTimeout || defaultTimeoutHandler

  useEffect(() => {
    if (!isVisible) return
    const timer = setTimeout(handleTimeout, timeout)
    return () => clearTimeout(timer)
  }, [timeout, handleTimeout, isVisible])

  if (!isVisible) return null

  return (
    <Box sx={{ ...containerStyles }} role="alert" aria-live="assertive">
      <CircularProgress
        variant="indeterminate"
        color="secondary"
        size={100}
        thickness={4}
        aria-label="Carregando"
        sx={{
          color: "primary.main",
        }}
      />
      <Typography
        variant="body2"
        component="span"
        sx={{
          marginTop: 2,
          textAlign: "center",
        }}
      >
        {text}
      </Typography>
    </Box>
  )
}

export default LoadingAnimation
