"use client"

import LoginButton from "@/components/ui/button/LoginButton"
import { StepCompanyCard } from "@/components/ui/card/company-tutorial-steps/StepCompanyCard"
import { StepCompanyDescription } from "@/components/ui/card/company-tutorial-steps/StepCompanyDescription"
import { getAssetsPath } from "@/utils/getAssetsPathUtils"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined"
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined"
import { CardMedia, Grid, Grow, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { useState } from "react"
import { InView } from "react-intersection-observer"

export default function CompanyStoreTutorial() {
  const [isTutorialLoaded, setIsTutorialLoaded] = useState(false)

  return (
    <section id="company-tutorial">
      <InView
        as="section"
        onChange={(inView) => {
          setIsTutorialLoaded(inView)
        }}
        threshold={0}
        triggerOnce
      >
        <Grow key={undefined} in={isTutorialLoaded} timeout={1000}>
          <Grid container spacing={2} mb={5} alignContent={{ md: "center", lg: "unset" }}>
            <Grid item xs={12} sm={12} md={8}>
              <Box
                sx={{
                  height: "100%",
                  background: "linear-gradient(88deg, #FFF 3.23%, #F6F5F8 95.06%)",
                  borderRadius: "24px",
                  padding: "1rem 0",
                }}
                alignContent={"center"}
              >
                <StepCompanyDescription
                  title={"Cadastre sua empresa de forma simples"}
                  image={"/img/logo-govbr.svg"}
                  content={
                    <>
                      Para cadastrar empresa também é necessário ter conta{" "}
                      <strong>gov.br?</strong> Sim! Assim como outros serviços oficiais do
                      Governo, o MS Qualifica Digital possui login unificado pelo gov.br.
                      Mais praticidade e segurança para você
                    </>
                  }
                />
                <Grid container>
                  <StepCompanyCard
                    icon={
                      <PersonAddOutlinedIcon
                        sx={{
                          color: "#114E99",
                          fontSize: "40px",
                          margin: { md: "0.6rem", lg: "1rem 0" },
                        }}
                      />
                    }
                    title={"Entre usando sua conta gov.br"}
                    content={
                      <>
                        <Typography>
                          Basta informar os dados que você usa para logar com sua conta
                          gov.br.
                        </Typography>
                        <LoginButton buttonsSx={{ m: 1 }} />
                      </>
                    }
                  />
                  <StepCompanyCard
                    icon={
                      <WorkOutlineOutlinedIcon
                        sx={{
                          color: "#114E99",
                          fontSize: "40px",
                          margin: { md: "0.6rem", lg: "1rem 0" },
                        }}
                      />
                    }
                    title={"Acesse o painel trabalhador"}
                    content={
                      <>
                        Ele é o painel vinculado ao seu CPF. Nele, você encontrará o
                        acesso ao formulário de cadastro de empresas.
                      </>
                    }
                  />
                  <StepCompanyCard
                    icon={
                      <AddCircleOutlineOutlinedIcon
                        sx={{
                          color: "#114E99",
                          fontSize: "40px",
                          margin: { md: "0.6rem", lg: "1rem 0" },
                        }}
                      />
                    }
                    title={"Cadastre uma ou várias empresas"}
                    content={
                      <>
                        Preencha os dados da empresa a ser cadastrada e pronto, você será
                        redirecionado ao painel de empresa.
                      </>
                    }
                  />
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              sx={{
                display: "flex",
                background:
                  "linear-gradient(271deg, rgba(255, 255, 255, 0.00) 68.7%, rgba(246, 245, 248, 0.20) 99.29%)",
              }}
            >
              <CardMedia
                component="video"
                src={`${getAssetsPath()}/mp4/msq-preview.mp4`}
                autoPlay={true}
                muted={true}
                loop={true}
                playsInline={true}
                sx={{
                  height: { sm: "90%", md: "100%" },
                  objectFit: { sm: "contain", md: "cover" },
                  width: "unset",
                  margin: "auto",
                  maxWidth: { xs: "300px", sm: "600px", md: "100%" },
                  borderRadius: "36px",
                }}
              />
            </Grid>
          </Grid>
        </Grow>
      </InView>
    </section>
  )
}
